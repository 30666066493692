<template>
  <div class="Awarp">
    <dashCard class="lvscBox10" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
              @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('lvscBox20_realtimeParam','断路器运行参数', getZEdata)}}</template>
      <template slot="aside">
        <a-select v-model="searchObj['ITEM']" style="width: 220px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
          <a-select-option v-for="(item,key) in itemList" :key="key" :value="item.assetNumber">
            {{ item.assetName }}
          </a-select-option>
        </a-select>
      </template>
      <div class="card-content1">
        <div class="product-pre">
          <div style="margin-right: 10%;">
            <img :src="detailInfo.productSrc"/>
          </div>
        </div>
        <div class="product-info" >
          <div class="product-status" style="display: flex;align-items: center;margin-bottom: 18px;">
            <div >{{commonLangHandler('photovoltaicBox11_working','工作状态', getZEdata)}}</div>
            <div style="margin-left: 35px;">
              <font>{{detailInfo.status}}</font>
            </div>
          </div>
          <div class="product-info-item" v-for="item in detailInfo.list">
            <div class="product-info-item-top">
              <span>{{item.name}} <span v-if="item.name == '运行电流'">(A)</span> <span v-if="item.name == '运行电压'">(V)</span> <span v-if="item.name == '有功功率'">(kW)</span></span>
              <span>{{Number(item.value).toFixed(2)}}</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{width: perceCalc(item.min,item.max,item.value)+'%'}"></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{Number(item.max).toFixed(2)}}</span>
            </div>
          </div>

        </div>
      </div>
      <!--                <a-descriptions bordered>-->
      <!--                    <a-descriptions-item v-for="(item,idx) in columns" :key="idx" :label="item.title" :span="3">-->
      <!--                        <span v-if="!!item.value1">{{ detailInfo[item.value1] }}</span>-->
      <!--                        <span v-if="!!item.value2"> {{ detailInfo[item.value2] }}</span>-->
      <!--                    </a-descriptions-item>-->
      <!--                </a-descriptions>-->
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
             width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <lvscBox10 :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Img001 from "@/assets/imgs/echarts/图片1.png"

export default {
  name: 'lvscBox20',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6}
      }
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getOperateWidth()
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中

      itemList: [
        // {
        //   code: '1AT1_抽屉柜',
        //   name: '1AT1_抽屉柜'
        // },
        // {
        //   code: '1AT2_抽屉柜',
        //   name: '1AT2_抽屉柜'
        // },
        // {
        //   code: '1AT3_抽屉柜',
        //   name: '1AT3_抽屉柜'
        // }
      ],

      columns: [
        {title: '型号', value1: 'value1'},
        {title: '上次维护时间', value1: 'value2', value2: 'value3'},
        {title: '额定电压', value1: 'value4', value2: 'value5'},
        {title: '额定电流', value1: 'value6', value2: 'value7',},
        {title: '额定频率', value1: 'value8', value2: 'value9'},
      ],
      searchObj: {
        ITEM: '',//选中的项目
      },
      detailInfo: {},
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    perceCalc(min,max,num){
            let perce = 0;
            if(isNaN(min)||isNaN(max)||isNaN(num)){
              perce = 0
            }else{
              if((Number(max)-Number(min)) == 0 || (Number(num)-Number(min)) == 0){
                perce = 0
              }else{
                perce = (Number(num)-Number(min))/(Number(max)-Number(min))
              }
            }
            if(perce < 0 || perce == 0){
              return 0
            }else if(perce > 1 || perce == 1){
              return 100
            }else{
              return perce*100
            }
          },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type: "电表"
        }
      }).then(res => {
        if(!res.data.data){
          this.itemList = []
        }else{
        this.itemList = res.data.data
        if (this.itemList.length !== 0) {
          this.searchObj.ITEM = this.itemList[0].assetNumber
        }}
        this.getData()
      })
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if (!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if (!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    // 切换项目
    handleChange(value) {
      this.getData()
    },
    // 获取数据
    getData() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/cb/status",
        method: "post",
        data: {
          assetNumber: this.searchObj.ITEM,
        }
      }).then(res => {
        this.isLoading = true
        let result = res.data.data
        if(result.length === 0){
          this.detailInfo = {
            productSrc: Img001,
            status: "",
          }
          this.detailInfo["list"] = result
          this.isLoading = false
          return
        }
        let index = result.findIndex(item => {
          return item.name === "工作状态"
        })
        this.detailInfo = {
          productSrc: Img001,
          status: result[index].value
        }
        let lang = sessionStorage.getItem("locale") || 'CH'
           
            if(lang=='EN'){
                  if(this.detailInfo.status =='未知'){
                      this.detailInfo.status = 'unknown'
                  }
            }
        result.splice(index,1)
        this.detailInfo["list"] = result
        
        //console.log(this.detailInfo)
        this.isLoading = false
        //console.log(res)
      })

    },
    getData0() {
      this.isLoading = true
      this.detailInfo = {
        value1: "ABB_3P 63A-400A",
        value2: "128",
        value3: "h",
        value4: "400",
        value5: "V",
        value6: "1000",
        value7: "A",
        value8: "2780",
        value9: "kWh",


        value10: "380V",
        value11: "200kW",
        value12: "80℃",
        productSrc: Img001
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.getOperateWidth()
    if (!this.option.visible) {
      this.getList()
      // this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.lvscBox10 {
  .card-content1 {
    display: flex;

    .product-pre {
      width: 300px;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .product-info {
      flex: 1;

      .product-status {
        div {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
          }

          &:nth-child(2) {
            margin-top: 6px;
            margin-bottom: 7px;

            font-size: 23px;
            font-weight: 500;
            color: #3366ff;
            line-height: 20px;
          }
        }
      }

      .product-info-item {
        margin-bottom: 10px;

        .product-info-item-top {
          display: flex;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 20px;
        }

        .product-info-item-progress {
          width: 100%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 14px;
          margin-bottom: 9px;

          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51A34B;
          }
        }

        .product-info-item-bottom {
          display: flex;
          justify-content: space-between;

          font-size: 14px;
          font-weight: 400;
          color: #696969;
          line-height: 16px;
        }
      }

    }

  }

  /deep/ .ant-descriptions-bordered {
    .ant-descriptions-item-label {
      background: transparent;
      width: 362px;
    }

    .ant-descriptions-item-content {
      background: #F5F5F5;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
